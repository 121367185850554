import { render, staticRenderFns } from "./Dashboards.vue?vue&type=template&id=1e3fa3f7&scoped=true&"
import script from "./Dashboards.vue?vue&type=script&lang=ts&"
export * from "./Dashboards.vue?vue&type=script&lang=ts&"
import style0 from "./Dashboards.vue?vue&type=style&index=0&id=1e3fa3f7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e3fa3f7",
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QTable,QInput,QIcon,QBtn,QTr,QTd,QBanner});













































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { apiGet } from '../../store';

@Component({name: 'Dashboards'})
export default class extends Vue {
  public $store: any;
  public $q: any;
  public dashboards: any = [];
  public loading: boolean = true;
  public filter: string = '';
  private columns: any = [
    {
      name: 'name',
      required: true,
      label: 'Nom',
      align: 'left',
      field: (row: any) => row.title_fr,
      sortable: true,
      headerStyle: 'max-width: 100px',
    },
  ];

  public async created() {
    this.refresh();
  }

  private refresh() {
    this.loading = true;
    apiGet(`/admin/dashboards`).then((response: any) => {
      this.dashboards = response;
      this.loading = false;
    }).catch((error: any) => {
      this.loading = false;
      this.globalError(error);
    });
  }

}
